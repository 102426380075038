import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaExchangeAlt, FaSpinner, FaExclamationTriangle } from 'react-icons/fa';
import { useDataContext } from "../Context/dataContext";
import flagEurope from '../Assets/Images/union-europea.png';
import flagVenezuela from '../Assets/Images/venezuelaflag.png';
import flagUSA from '../Assets/Images/EEUU.png';
import { Link } from 'react-router-dom';

function CurrencyCalculatorUSA() {
  const { infoTkn, url } = useDataContext();
  const [exchangeRateEur, setExchangeRateEur] = useState(0);
  const [exchangeRateUsd, setExchangeRateUsd] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [amount, setAmount] = useState('100');  // Mantener como string para permitir edición
  const [vefAmount, setVefAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [highlight, setHighlight] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setHighlight(false), 5000); // Deja de titilar tras 5 segundos
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(`${url}/CurrencyPrice`, {
          headers: {
            Authorization: `Bearer ${infoTkn}`,
          },
        });
        const data = response.data;

        const rateDataEur = data.find((item) => item.cur_id === 1);
        const rateDataUsd = data.find((item) => item.cur_id === 1);
        if (rateDataEur && rateDataUsd) {
          setExchangeRateEur(rateDataEur.cur_EurToBs);
          setExchangeRateUsd(rateDataUsd.cur_UsdToBs);
          setVefAmount((100 * rateDataUsd.cur_UsdToBs).toFixed(2)); // Inicializamos con 100 USD
        } else {
          setError('Tasa de cambio no encontrada para EUR o USD');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching exchange rate', error);
        setError('Error fetching exchange rate');
        setLoading(false);
      }
    };

    fetchExchangeRate();
  }, [infoTkn, url]);

  const handleAmountChange = (e) => {
    const value = e.target.value;

    // Validar que el valor ingresado sea un número válido o vacío (permitir borrar)
    if (!isNaN(value) || value === '') {
      setAmount(value);
      const amountNumber = parseFloat(value);
      
      // Actualizar el monto en VEF solo si hay un valor válido
      if (!isNaN(amountNumber)) {
        const rate = selectedCurrency === 'EUR' ? exchangeRateEur : exchangeRateUsd;
        setVefAmount((amountNumber * rate).toFixed(2));
      } else {
        setVefAmount(0); // Si el valor es vacío o no es número, poner VEF a 0
      }
    }
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
    const amountNumber = parseFloat(amount);
    if (!isNaN(amountNumber)) {
      const rate = e.target.value === 'EUR' ? exchangeRateEur : exchangeRateUsd;
      setVefAmount((amountNumber * rate).toFixed(2));
    }
  };

  if (loading) return <div className="loading"><FaSpinner className="spinner" /> Cargando tasa de cambio...</div>;
  if (error) return <div className="error"><FaExclamationTriangle /> {error}</div>;

  return (
    <div className="currency-calculator">
      <div className="exchange-rate"> {/* Fondo verde aplicado aquí */}
        <FaExchangeAlt className="exchange-icon" />
        <p className="rate-text">Tasa de cambio: <span className="rate-value">1 {selectedCurrency} = {selectedCurrency === 'EUR' ? exchangeRateEur.toFixed(2) : exchangeRateUsd.toFixed(2)} VES</span></p>
      </div>

      <div className="calculator-horizontal">
        <div className="input-group">
          <label htmlFor="currency">
            <img src={selectedCurrency === 'EUR' ? flagEurope : flagUSA} alt="Bandera" className="flag-icon" />
            Envías:
          </label>
          <select className={highlight ? 'highlight' : ''} id="currency" value={selectedCurrency} onChange={handleCurrencyChange}>
            <option value="EUR">EUR</option>
            <option value="USD">USD</option>
          </select>
          <input
            type="text" // Mantener como texto para permitir entradas no numéricas temporales
            id="amount"
            value={amount} // Valor editable
            onChange={handleAmountChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="vef">
            <img src={flagVenezuela} alt="Bandera de Venezuela" className="flag-icon" />
            Recibes (VEF):
          </label>
          <input
            type="text"
            id="vef"
            value={vefAmount} // Mostrar el valor en VEF calculado con 2 decimales
            readOnly
          />
        </div>
      </div>

      <div className="summary-section">
        <div className="summary-item">
          <p>Comisión</p>
          <span className="summary-value">+ 0,00 {selectedCurrency}</span>
        </div>

        <div className="summary-item">
          <p>Total a pagar</p>
          <span className="summary-value">{amount !== '' ? `${amount} ${selectedCurrency}` : `0.00 ${selectedCurrency}`}</span> {/* Mostrar '0.00' si está vacío */}
        </div>
      </div>

      <Link to='/Register'><button>Enviar ahora</button></Link>
    </div>
  );
}

export { CurrencyCalculatorUSA };
